// TODO: Don't submit form multiple times at the end
// Polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
if (!Object.keys) {
  Object.keys = (function () {
    "use strict"
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      hasDontEnumBug = !{ toString: null }.propertyIsEnumerable("toString"),
      dontEnums = [
        "toString",
        "toLocaleString",
        "valueOf",
        "hasOwnProperty",
        "isPrototypeOf",
        "propertyIsEnumerable",
        "constructor",
      ],
      dontEnumsLength = dontEnums.length

    return function (obj) {
      if (
        typeof obj !== "object" &&
        (typeof obj !== "function" || obj === null)
      ) {
        throw new TypeError("Object.keys called on non-object")
      }

      var result = [],
        prop,
        i

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop)
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i])
          }
        }
      }
      return result
    }
  })()
}

function S3DirectPost(image_arr, post_url, keyCallback) {
  this.post_url = post_url
  this.returned_keys = []
  this.image_arr = image_arr
  this.keyCallback = keyCallback

  _this = this

  this.submitToS3 = function () {
    console.log(this.post_url)
    console.log("started")

    for (var i = 0; i < this.image_arr.length; ++i) {
      ;(function (image, url, callback) {
        var form = new FormData()
        var req = new XMLHttpRequest()

        form.append("name", image.files[0].name)
        form.append("type", image.files[0].type)
        console.log(image.files[0].name)

        req.open("POST", url, true)
        req.setRequestHeader(
          "X-CSRF-Token",
          document.querySelector('meta[name="csrf-token"]').content
        )

        req.onerror = function (e) {
          console.log("error occured: " + e)
        }

        req.onload = function () {
          console.log(this.responseText)
          var data = JSON.parse(this.responseText)
          if (this.status === 200) {
            callback(image.files[0], data)
          }
        }

        req.send(form)
      })(this.image_arr[i], this.post_url, this.s3Post)
    }
  }

  this.s3Post = function (file, data) {
    var req = new XMLHttpRequest()
    var form = new FormData()

    var url = data["form_action"]
    delete data["form_action"]

    Object.keys(data).forEach(function (key) {
      form.append(key, data[key])
    })

    form.append("file", file)
    req.open("POST", url, true)

    req.onerror = function (e) {
      console.log("an error occured: " + e)
    }

    req.onload = function () {
      console.log(this.responseText)
      var xml = new DOMParser().parseFromString(this.responseText, "text/xml")
      var tag = xml.getElementsByTagName("Key")[0]
      // Fixes IE bug where childNodes are arbitrarily split
      var keyArr = []
      for (var k = 0; k < tag.childNodes.length; ++k) {
        keyArr.push(tag.childNodes[k].nodeValue)
      }
      var key = keyArr.join("")
      console.log(key)

      // Instead of Promises (because not fully supported), adding keys to array
      // and when the array is the same length as the fields with images, return
      _this.returned_keys.push(key)
      if (_this.image_arr.length === _this.returned_keys.length) {
        _this.keyCallback(_this.returned_keys)
      }
    }

    var progressBar = document.getElementById("progress-bar")
    var barContainer = document.createElement("div")

    var barText = document.createElement("p")
    barText.innerText = file.name

    var bar = document.createElement("div")
    bar.className = "upload-bar"

    barContainer.appendChild(barText)
    barContainer.appendChild(bar)

    if (progressBar) {
      progressBar.appendChild(barContainer)
      req.upload.onprogress = function (data) {
        var pct = Math.round((data.loaded * 100) / data.total)
        bar.style.width = pct + "%"
      }
    }

    req.send(form)
  }

  this.returnKeys = function (file, data) {
    // Instead of Promises (because not fully supported), adding keys to array
    // and when the array is the same length as the fields with images, return
    var key = _this.s3Post(file, data)

    _this.returned_keys.push(key)
    console.log(_this.returned_keys)
    if (_this.image_arr.length === _this.returned_keys.length) {
      return _this.returned_keys
    }
  }
}

window.S3DirectPost = S3DirectPost
