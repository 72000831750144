module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<p>\n  Here\'s a preview of the email we\'ll send to <%= landlord.fullName() %>:\n</p>\n\n<pre>\nYour tenant <%= issue.get("tenant").fullName() %> is using <a href="http://squaredawaychicago.com">Squared Away</a> by <a href="http://www.tenants-rights.org/">MTO</a>, to help manage their apartment. They\'ve indicated you as their landlord. They wanted you to know that there\'s a problem with <%= categoryList.join(", ") %> in their <%= issue.get("area_of_residence").join(", ") %>. Here is the description:\n\n  <%= issue.get("message") %>\n\n<%= issue.get("tenant").fullName() %>\n<%= issue.get("address").get("street") %>\n<%= issue.get("tenant").get("email") %>\n</pre>\n\n<input id="step-one" class="button" type="button" value="&laquo; Edit Issue"/>\n<input id="submit-issue" class="button" type="button" value="Send to <%= landlord.fullName() %> &raquo;"/>\n<div id="progress-bar" class="progress"></div>\n<a href="/" class="button">Cancel</a>\n', __filename = "app/javascript/src/templates/issue_email_preview.jst.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append("<p>\n  Here's a preview of the email we'll send to ");
            __line = 2;
            __append(escapeFn(landlord.fullName()));
            __append(":\n</p>\n\n<pre>\nYour tenant ");
            __line = 6;
            __append(escapeFn(issue.get("tenant").fullName()));
            __append(' is using <a href="http://squaredawaychicago.com">Squared Away</a> by <a href="http://www.tenants-rights.org/">MTO</a>, to help manage their apartment. They\'ve indicated you as their landlord. They wanted you to know that there\'s a problem with ');
            __append(escapeFn(categoryList.join(", ")));
            __append(" in their ");
            __append(escapeFn(issue.get("area_of_residence").join(", ")));
            __append(". Here is the description:\n\n  ");
            __line = 8;
            __append(escapeFn(issue.get("message")));
            __append("\n\n");
            __line = 10;
            __append(escapeFn(issue.get("tenant").fullName()));
            __append("\n");
            __line = 11;
            __append(escapeFn(issue.get("address").get("street")));
            __append("\n");
            __line = 12;
            __append(escapeFn(issue.get("tenant").get("email")));
            __append('\n</pre>\n\n<input id="step-one" class="button" type="button" value="&laquo; Edit Issue"/>\n<input id="submit-issue" class="button" type="button" value="Send to ');
            __line = 16;
            __append(escapeFn(landlord.fullName()));
            __append(' &raquo;"/>\n<div id="progress-bar" class="progress"></div>\n<a href="/" class="button">Cancel</a>\n');
            __line = 19;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}