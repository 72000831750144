// eslint-disable-next-line
const images = require.context("../images", true)
// eslint-disable-next-line
const imagePath = (name) => images(name, true)

require("jquery")
require("underscore")

require("backbone")
require("@nathanvda/cocoon")

require("../src/lib/extensions")
require("../src/lib/existence")
require("../src/lib/tabs")

require("../src/app")

require("../src/lib/validator")
require("../src/lib/router")
require("../src/lib/messenger")
require("../src/lib/s3post")

require("../src/models")
require("../src/views/issue_wizard")
require("../src/views/googlegeo")
require("../src/controllers")
require("../src/flash")
