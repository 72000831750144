module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="what-happens-next">\n  <h2>All set?</h2>\n  <p>What happens next:</p>\n  <% if (landlord.get("email")) { %>\n    <p>If you would like to send an email to your landlord:</p>\n    <ul>\n      <li>We\'ll send an email to <%= landlord.get("email") %> to track this issue</li>\n      <li>We\'ll also send an email to <%= tenant.get("email") %></li>\n      <li>The landlord should follow up shortly</li>\n    </ul>\n    <p>To preview your issue before sending it to your landlord, click "Next step"</p>\n    <input id="preview-email" class="button" type="button" value="Next step &raquo;"/>\n    <p>If you do <b>not</b> want to send an issue to your landlord, we will still\n      send you an email, and you\'ll be able to choose to send emails to your landlord\n      later.</p>\n    <p>To submit your issue without emailing your landlord, click "Submit Issue"</p>\n    <div id="progress-bar" class="progress"></div>\n    <input id="submit-no-email" class="button" type="button" value="Submit Issue &raquo;"/>\n  <% } else { %>\n    <ul>\n      <li>We\'ll send an email to <%= tenant.get("email") %> to track this issue</li>\n    </ul>\n    <input id="step-one" class="button" type="button" value="&laquo; Edit Issue"/>\n    <input id="submit-issue" class="button" type="button" value="Submit Issue &raquo;"/>\n    <a href="/" class="button">Cancel</a>\n  <% } %>\n</div>\n', __filename = "app/javascript/src/templates/what_happens_next.jst.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="what-happens-next">\n  <h2>All set?</h2>\n  <p>What happens next:</p>\n  ');
            __line = 4;
            if (landlord.get("email")) {
                __append("\n    <p>If you would like to send an email to your landlord:</p>\n    <ul>\n      <li>We'll send an email to ");
                __line = 7;
                __append(escapeFn(landlord.get("email")));
                __append(" to track this issue</li>\n      <li>We'll also send an email to ");
                __line = 8;
                __append(escapeFn(tenant.get("email")));
                __append('</li>\n      <li>The landlord should follow up shortly</li>\n    </ul>\n    <p>To preview your issue before sending it to your landlord, click "Next step"</p>\n    <input id="preview-email" class="button" type="button" value="Next step &raquo;"/>\n    <p>If you do <b>not</b> want to send an issue to your landlord, we will still\n      send you an email, and you\'ll be able to choose to send emails to your landlord\n      later.</p>\n    <p>To submit your issue without emailing your landlord, click "Submit Issue"</p>\n    <div id="progress-bar" class="progress"></div>\n    <input id="submit-no-email" class="button" type="button" value="Submit Issue &raquo;"/>\n  ');
                __line = 19;
            } else {
                __append("\n    <ul>\n      <li>We'll send an email to ");
                __line = 21;
                __append(escapeFn(tenant.get("email")));
                __append(' to track this issue</li>\n    </ul>\n    <input id="step-one" class="button" type="button" value="&laquo; Edit Issue"/>\n    <input id="submit-issue" class="button" type="button" value="Submit Issue &raquo;"/>\n    <a href="/" class="button">Cancel</a>\n  ');
                __line = 26;
            }
            __append("\n</div>\n");
            __line = 28;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}