//= require ./landlords_controller
//= require ./tenants_controller
//= require ./units_controller
//= require ./check_in_controller
//= require ./issues_controller
require("./landlords_controller")
require("./tenants_controller")
require("./units_controller")
require("./check_in_controller")
require("./issues_controller")
