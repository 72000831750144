module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="lookup-landlord">\n  <h2>Search for Landlord</h2>\n  <p>\n    We need a piece of identifying information to find the landlord for the given address. Enter his/her email or phone number(###-###-####) below:\n  </p>\n  <input type="text" name="email-phone" id="phone-email-field" placeholder="Email or Phone number" value="<%= query %>" required="required"/>\n\n  <input id="find-landlord" class="button" type="button" value="Find Landlord &raquo;"/>\n  <a href="/" class="button">Cancel</a>\n</div>\n\n<% if (alreadySearched)  { %>\n  <p class="not-found">\n    We could not find a landlord in our system with the email or phone number <strong>"<%= query %>"</strong>. You can try to search for them again or add landlord\'s information below:\n  </p>\n<% } %>\n', __filename = "app/javascript/src/templates/lookup_landlord.jst.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="lookup-landlord">\n  <h2>Search for Landlord</h2>\n  <p>\n    We need a piece of identifying information to find the landlord for the given address. Enter his/her email or phone number(###-###-####) below:\n  </p>\n  <input type="text" name="email-phone" id="phone-email-field" placeholder="Email or Phone number" value="');
            __line = 6;
            __append(escapeFn(query));
            __append('" required="required"/>\n\n  <input id="find-landlord" class="button" type="button" value="Find Landlord &raquo;"/>\n  <a href="/" class="button">Cancel</a>\n</div>\n\n');
            __line = 12;
            if (alreadySearched) {
                __append('\n  <p class="not-found">\n    We could not find a landlord in our system with the email or phone number <strong>"');
                __line = 14;
                __append(escapeFn(query));
                __append("\"</strong>. You can try to search for them again or add landlord's information below:\n  </p>\n");
                __line = 16;
            }
            __append("\n");
            __line = 17;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}