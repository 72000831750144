module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<h2>Give this issue a title</h2>\n<input type="text" name="title" id="title-field" placeholder="e.g. Broken sink" required="required" value="<%= issue.get(\'title\') %>" />\n<% if (user.isNew()) { %>\n  <h2>Provide a password to check on the status of this issue in the future.</h2>\n  <p>Or enter your password if you\'re already a member.</p>\n  <p id="password-warn">Password must be at least 8 characters.</p>\n  <div id="incorrect-password" style="display:none;">\n    <p style="color:red;">Incorrect password for existing user.</p>\n    <a href="/password/new" target="_blank">Forgot your password?</a>\n  </div>\n  <input type="password" name="new-password" id="new-password-field" placeholder="Password" required="required" value="<%= user.get(\'password\') %>"/>\n<% } %>\n<input id="step-three" class="button" type="button" value="Next step &raquo;"/>\n<a href="/" class="button">Cancel</a>\n', __filename = "app/javascript/src/templates/add_issue_title.jst.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<h2>Give this issue a title</h2>\n<input type="text" name="title" id="title-field" placeholder="e.g. Broken sink" required="required" value="');
            __line = 2;
            __append(escapeFn(issue.get("title")));
            __append('" />\n');
            __line = 3;
            if (user.isNew()) {
                __append('\n  <h2>Provide a password to check on the status of this issue in the future.</h2>\n  <p>Or enter your password if you\'re already a member.</p>\n  <p id="password-warn">Password must be at least 8 characters.</p>\n  <div id="incorrect-password" style="display:none;">\n    <p style="color:red;">Incorrect password for existing user.</p>\n    <a href="/password/new" target="_blank">Forgot your password?</a>\n  </div>\n  <input type="password" name="new-password" id="new-password-field" placeholder="Password" required="required" value="');
                __line = 11;
                __append(escapeFn(user.get("password")));
                __append('"/>\n');
                __line = 12;
            }
            __append('\n<input id="step-three" class="button" type="button" value="Next step &raquo;"/>\n<a href="/" class="button">Cancel</a>\n');
            __line = 15;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}