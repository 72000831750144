module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div id="building-<%= i %>" class="address-field">\n  <input type="text" id="address_street" name="addresses[<%= i %>][street]" placeholder="Street"/>\n  <input type="text" id="address_unit_number" name="addresses[<%= i %>][unit_number]" placeholder="Unit #"/>\n  <input type="text" id="address_city" name="addresses[<%= i %>][city]" placeholder="City"/>\n  <input type="text" id="address_state" name="addresses[<%= i %>][state]" placeholder="State"/>\n  <input type="text" id="address_zip" maxlength="5" name="addresses[<%= i %>][zip]" placeholder="Zip"/>\n  <input type="text" id="address_lat" name="addresses[<%= i %>][lat]" placeholder="Lat" hidden/>\n  <input type="text" id="address_lon" name="addresses[<%= i %>][lon]" placeholder="Lon" hidden/>\n</div>\n', __filename = "app/javascript/src/templates/address_field.jst.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div id="building-');
            __append(escapeFn(i));
            __append('" class="address-field">\n  <input type="text" id="address_street" name="addresses[');
            __line = 2;
            __append(escapeFn(i));
            __append('][street]" placeholder="Street"/>\n  <input type="text" id="address_unit_number" name="addresses[');
            __line = 3;
            __append(escapeFn(i));
            __append('][unit_number]" placeholder="Unit #"/>\n  <input type="text" id="address_city" name="addresses[');
            __line = 4;
            __append(escapeFn(i));
            __append('][city]" placeholder="City"/>\n  <input type="text" id="address_state" name="addresses[');
            __line = 5;
            __append(escapeFn(i));
            __append('][state]" placeholder="State"/>\n  <input type="text" id="address_zip" maxlength="5" name="addresses[');
            __line = 6;
            __append(escapeFn(i));
            __append('][zip]" placeholder="Zip"/>\n  <input type="text" id="address_lat" name="addresses[');
            __line = 7;
            __append(escapeFn(i));
            __append('][lat]" placeholder="Lat" hidden/>\n  <input type="text" id="address_lon" name="addresses[');
            __line = 8;
            __append(escapeFn(i));
            __append('][lon]" placeholder="Lon" hidden/>\n</div>\n');
            __line = 10;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}