module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<h2>We think the landlord is</h2>\n<div class="landlord-card">\n  <% if(landlord.get(\'image\')) { %>\n    <img class="image" src="<%= landlord.get(\'image\').thumb %>"/>\n  <% } %>\n  <p class="name"><%= landlord.fullName() %></p>\n  <% if (landlord.get(\'address\')) { %>\n    <% if (landlord.get(\'address\').get(\'city\')) { %>\n      <p class="street"><%= landlord.get(\'address\').streetAndUnit() %></p>\n      <p class="city"><%= landlord.get(\'address\').cityStateZip()%></p>\n    <% } %>\n  <% } %>\n  <% if (landlord.get("phone_number")) { %>\n    <p class="phone"><%= landlord.get("phone_number")%></p>\n  <% } %>\n  <% if (landlord.get("email")) { %>\n    <p class="email"><%= landlord.get("email") %></p>\n  <% } %>\n</div>\n<input id="change-landlord" class="button" type="button" value="&laquo; Change Landlord"/>\n<input id="add-title" class="button" type="button" value="Next step &raquo;"/>\n<a href="/" class="button">Cancel</a>\n', __filename = "app/javascript/src/templates/suggested_landlord.jst.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<h2>We think the landlord is</h2>\n<div class="landlord-card">\n  ');
            __line = 3;
            if (landlord.get("image")) {
                __append('\n    <img class="image" src="');
                __line = 4;
                __append(escapeFn(landlord.get("image").thumb));
                __append('"/>\n  ');
                __line = 5;
            }
            __append('\n  <p class="name">');
            __line = 6;
            __append(escapeFn(landlord.fullName()));
            __append("</p>\n  ");
            __line = 7;
            if (landlord.get("address")) {
                __append("\n    ");
                __line = 8;
                if (landlord.get("address").get("city")) {
                    __append('\n      <p class="street">');
                    __line = 9;
                    __append(escapeFn(landlord.get("address").streetAndUnit()));
                    __append('</p>\n      <p class="city">');
                    __line = 10;
                    __append(escapeFn(landlord.get("address").cityStateZip()));
                    __append("</p>\n    ");
                    __line = 11;
                }
                __append("\n  ");
                __line = 12;
            }
            __append("\n  ");
            __line = 13;
            if (landlord.get("phone_number")) {
                __append('\n    <p class="phone">');
                __line = 14;
                __append(escapeFn(landlord.get("phone_number")));
                __append("</p>\n  ");
                __line = 15;
            }
            __append("\n  ");
            __line = 16;
            if (landlord.get("email")) {
                __append('\n    <p class="email">');
                __line = 17;
                __append(escapeFn(landlord.get("email")));
                __append("</p>\n  ");
                __line = 18;
            }
            __append('\n</div>\n<input id="change-landlord" class="button" type="button" value="&laquo; Change Landlord"/>\n<input id="add-title" class="button" type="button" value="Next step &raquo;"/>\n<a href="/" class="button">Cancel</a>\n');
            __line = 23;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}